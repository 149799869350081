import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import Layout from "../components/layout";

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          id
          parent {
            ... on File {
              id
              name
              relativePath
              sourceInstanceName
            }
          }
          frontmatter {
            titol
            ordre
            resum
            imatge {
              publicURL
            }
          }
        }
      }
    }
  `);

  console.log(data);

  const material = data.allMarkdownRemark.nodes
    .filter(({ parent }) => parent.sourceInstanceName === "material")
    .sort((a, b) => {
      return a.frontmatter.ordre - b.frontmatter.ordre;
    });

  return (
    <Layout pageTitle="Materials de lectura">
      <ul className="MaterialList">
        {material.map(({ frontmatter, id, parent }) => (
          <div key={id} className="MaterialList__item">
            <Link
              to={`/material/${parent.relativePath.split("/")[0]}/`}
              className="MaterialList__thumbnailLink"
            >
              <img
                src={frontmatter.imatge.publicURL}
                className="MaterialList__thumbnail"
              />
            </Link>
            <div>
              <Link to={`/material/${parent.relativePath.split("/")[0]}/`}>
                <h3>{frontmatter.titol}</h3>
              </Link>
              <p>{frontmatter.resum}</p>
            </div>
          </div>
        ))}
      </ul>
    </Layout>
  );
};

export default IndexPage;
